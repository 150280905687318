// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/components/layout/LeftMenu.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/components/layout/LeftMenu.tsx");
  import.meta.hot.lastModified = "1728047456214.0032";
}
// REMIX HMR END

import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogPanel, Transition, TransitionChild, Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import clsx from 'clsx';
import { DocumentDuplicateIcon, FolderIcon, HomeIcon, BanknotesIcon, UsersIcon, XMarkIcon, AcademicCapIcon, ChevronRightIcon, Cog6ToothIcon, PhotoIcon, FolderOpenIcon, ShoppingBagIcon, MegaphoneIcon, TagIcon, StarIcon, UserGroupIcon, Square3Stack3DIcon, MapPinIcon, ClockIcon, RssIcon, MapIcon, CurrencyDollarIcon, CalculatorIcon, Bars4Icon, GlobeAltIcon, TruckIcon, UserCircleIcon, BarsArrowDownIcon, CommandLineIcon, CalendarIcon, DocumentMagnifyingGlassIcon, SwatchIcon, AdjustmentsVerticalIcon, DocumentCheckIcon, IdentificationIcon } from '@heroicons/react/24/outline';
import { useLocation } from '@remix-run/react';
import { config as administratorConfig } from '~admin/modules/administrator/config';
import { config as agreementConfig } from '~admin/modules/agreement/config';
import { config as channelConfig } from '~admin/modules/channel/config';
import { config as countryConfig } from '~admin/modules/countries/config';
import { config as paymentMethodsConfig } from '~admin/modules/payment-methods/config';
import { config as shippingMethodsConfig } from '~admin/modules/shipping-methods/config';
import { config as taxRateConfig } from '~admin/modules/tax-rates/config';
import { config as taxCategoryConfig } from '~admin/modules/tax-categories/config';
import { config as zoneConfig } from '~admin/modules/zones/config';
import { config as staffConfig } from '~admin/modules/staff/config';
import { config as jobConfig } from '~admin/modules/job/config';
import { config as locationConfig } from '~admin/modules/location/config';
import { config as trainingAssetConfig } from '~admin/modules/training-asset/config';
import { config as orderConfig } from '~admin/modules/order/config';
import { config as paymentConfig } from '~admin/modules/payment/config';
import { config as productConfig } from '~admin/modules/product/config';
import { config as rankConfig } from '~admin/modules/stripe/config';
import { config as roleConfig } from '~admin/modules/role/config';
import { config as settingsConfig } from '~admin/modules/settings/config';
import { config as membershipConfig } from '~admin/modules/membership/config';
import { Permission } from '~admin/generated/graphql';
import { Auth } from '~common/components/security/Auth';
const schoolNavigation = [{
  name: 'School',
  href: '/school-settings/classes',
  icon: AcademicCapIcon,
  current: false,
  auth: [],
  children: [{
    name: 'Classes',
    href: '/school-settings/classes',
    icon: ClockIcon,
    current: false,
    auth: [Permission.ReadRsvClazz]
  }, {
    name: 'Staff',
    href: staffConfig.path,
    icon: UsersIcon,
    current: false,
    auth: [Permission.ReadRsvStaff]
  }, {
    name: 'Locations and Classrooms',
    href: locationConfig.path,
    icon: MapPinIcon,
    current: false,
    auth: [Permission.ReadRsvLocation]
  }, {
    name: 'Agreements',
    href: agreementConfig.path,
    icon: DocumentCheckIcon,
    current: false,
    auth: [Permission.ReadAgreement]
  }, {
    name: 'Schedule',
    href: `/school-settings/classes/schedule`,
    icon: CalendarIcon,
    current: false,
    auth: [Permission.ReadRsvClazz]
  }, {
    name: 'Training Assets',
    href: trainingAssetConfig.path,
    icon: Square3Stack3DIcon,
    current: false,
    auth: [Permission.ReadRsvTrainingAsset]
  }, {
    name: 'Belt / Rank Management',
    href: rankConfig.path,
    icon: SwatchIcon,
    current: false,
    auth: [Permission.ReadRsvRank, Permission.ReadRsvMartialArt]
  }]
}];
const navigation = [{
  name: 'Dashboard',
  href: '/',
  icon: HomeIcon,
  current: true,
  auth: []
}, {
  name: 'Clients',
  href: '#',
  icon: UsersIcon,
  current: false,
  children: [{
    name: 'Customers / Students',
    href: '/dashboard/customers',
    icon: UsersIcon,
    current: false,
    auth: [Permission.ReadCustomer]
  }, {
    name: 'Schools',
    href: '/dashboard/settings/sellers',
    icon: UserCircleIcon,
    current: false,
    auth: [Permission.ReadSeller]
  }],
  auth: [Permission.ReadSeller, Permission.ReadCustomer]
}, ...schoolNavigation, {
  name: 'Orders',
  href: orderConfig.path,
  icon: ShoppingBagIcon,
  current: false,
  auth: [Permission.ReadOrder]
}, {
  name: 'Finance',
  href: '#',
  icon: BanknotesIcon,
  current: false,
  children: [{
    name: 'Memberships',
    href: membershipConfig.path,
    icon: IdentificationIcon,
    current: false,
    auth: [Permission.ReadOrder, Permission.ReadRsvMembership]
  }, {
    name: 'Payments',
    href: paymentConfig.path,
    icon: BanknotesIcon,
    current: false,
    auth: [Permission.ReadOrder]
  }],
  auth: [Permission.ReadSeller, Permission.ReadCustomer]
}, {
  name: 'Catalog',
  href: '#',
  icon: FolderIcon,
  current: false,
  auth: [Permission.ReadProduct, Permission.ReadFacet, Permission.ReadCollection, Permission.ReadAsset],
  children: [{
    name: 'Products',
    href: '/dashboard/products',
    icon: DocumentDuplicateIcon,
    current: false,
    auth: [Permission.ReadProduct]
  }, {
    name: 'Facets',
    href: '/dashboard/facets',
    icon: TagIcon,
    current: false,
    auth: [Permission.ReadFacet]
  }, {
    name: 'Collections',
    href: '/dashboard/collections',
    icon: FolderOpenIcon,
    current: false,
    auth: [Permission.ReadCollection]
  }, {
    name: 'Assets',
    href: '/dashboard/assets',
    icon: PhotoIcon,
    current: false,
    auth: [Permission.ReadAsset]
  }]
}, {
  name: 'Marketing',
  href: '#',
  icon: MegaphoneIcon,
  current: false,
  auth: [],
  children: [{
    name: 'Promotions',
    href: '/dashboard/promotions',
    icon: TagIcon,
    current: false,
    auth: [Permission.ReadPromotion]
  }, {
    name: 'Product reviews',
    href: '/dashboard/product-reviews',
    icon: StarIcon,
    current: false,
    auth: []
  }]
}];
const systemNavigation = [{
  name: 'System',
  href: '#',
  icon: CommandLineIcon,
  current: false,
  auth: [Permission.ReadSystem, Permission.UpdateCatalog],
  children: [{
    name: 'Jobs',
    href: jobConfig.path,
    icon: BarsArrowDownIcon,
    current: false,
    auth: [Permission.ReadSystem]
  }, {
    name: 'Reindex',
    href: `${productConfig.path}/reindex`,
    icon: DocumentMagnifyingGlassIcon,
    current: false,
    auth: [Permission.UpdateCatalog]
  }, {
    name: 'Global Settings',
    href: settingsConfig.path,
    icon: AdjustmentsVerticalIcon,
    current: false,
    auth: [Permission.UpdateGlobalSettings]
  }]
}];
const settingsNavigation = [{
  name: 'Settings',
  href: '/dashboard/settings',
  icon: Cog6ToothIcon,
  current: false,
  auth: [],
  children: [{
    name: 'Administrators',
    href: administratorConfig.path,
    icon: UserGroupIcon,
    current: false,
    auth: [Permission.ReadAdministrator]
  }, {
    name: 'Channels',
    href: channelConfig.path,
    icon: RssIcon,
    current: false,
    auth: [Permission.ReadChannel]
  }, {
    name: 'Agreements',
    href: agreementConfig.path,
    icon: AcademicCapIcon,
    current: false,
    auth: [Permission.ReadAgreement]
  },
  // {
  //   name: 'Schools',
  //   href: sellerConfig.path,
  //   icon: UsersIcon,
  //   current: false,
  //   auth: [Permission.ReadSeller],
  // },
  {
    name: 'Countries',
    href: countryConfig.path,
    icon: MapIcon,
    current: false,
    auth: [Permission.ReadCountry]
  }, {
    name: 'Payment methods',
    href: paymentMethodsConfig.path,
    icon: CurrencyDollarIcon,
    current: false,
    auth: [Permission.ReadPaymentMethod]
  }, {
    name: 'Roles',
    href: roleConfig.path,
    icon: UserCircleIcon,
    current: false,
    auth: [Permission.ReadSettings]
  }, {
    name: 'Shipping methods',
    href: shippingMethodsConfig.path,
    icon: TruckIcon,
    current: false,
    auth: [Permission.ReadShippingMethod]
  }, {
    name: 'Tax Rates',
    href: taxRateConfig.path,
    icon: CalculatorIcon,
    current: false,
    auth: [Permission.ReadTaxRate]
  }, {
    name: 'Tax Categories',
    href: taxCategoryConfig.path,
    icon: Bars4Icon,
    current: false,
    auth: [Permission.ReadTaxCategory]
  }, {
    name: 'Zones',
    href: zoneConfig.path,
    icon: GlobeAltIcon,
    current: false,
    auth: [Permission.ReadZone]
  }]
}];
const MenuBox = ({
  item,
  administrator,
  isExpanded
}) => {
  _s();
  const location = useLocation();
  const {
    pathname
  } = location;
  const active = pathname.startsWith(item.href);
  const ref = useRef();
  useEffect(() => {
    if (!isExpanded && ref.current) {
      ref.current();
    }
  }, [isExpanded]);
  return <Auth customUser={administrator.user} permissions={item.auth}>
      <Disclosure as="div">
        {({
        open,
        close
      }) => <>
            <DisclosureButton className={clsx('flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold w-full', {
          'bg-gray-800 text-white': active,
          'text-gray-400 hover:text-white hover:bg-gray-800': !active
        })} ref={() => ref.current = close}>
              <item.icon className="h-6 w-6 shrink-0 text-gray-400 dark:text-slate-400" aria-hidden="true" />
              <span className={clsx(isExpanded ? 'inline' : 'hidden')}>
                {item.name}
              </span>
              {/* <span>
               {item.name}
               </span> */}
              <ChevronRightIcon className={clsx(open ? 'rotate-90 text-gray-500' : 'text-gray-400', 'ml-auto h-5 w-5 shrink-0', isExpanded ? 'inline' : 'hidden')} aria-hidden="true" />
            </DisclosureButton>

            <DisclosurePanel as="ul" className={clsx('mt-1', isExpanded ? 'space-y-1' : 'space-y-2')}>
              {item.children.map(subItem => {
            const subActive = pathname.startsWith(subItem.href);
            return <Auth customUser={administrator.user} permissions={subItem.auth} key={subItem.name}>
                    <li>
                      <DisclosureButton as="a" href={subItem.href} className={clsx('group flex gap-x-3 rounded-md text-sm leading-6 font-semibold', {
                  'bg-gray-800 text-white': subActive,
                  'text-gray-400 hover:text-white hover:bg-gray-800 ': !subActive
                }, isExpanded ? 'p-3 pl-12' : 'p-2 mx-2')}>
                        <subItem.icon className={clsx('shrink-0', isExpanded ? 'h-6 w-6' : 'w-4 h-4')} aria-hidden="true" />

                        <span className={clsx('truncate', isExpanded ? 'inline' : 'hidden')}>
                          {subItem.name}
                        </span>
                        {/* <span className="truncate">{subItem.name}</span> */}
                      </DisclosureButton>
                    </li>
                  </Auth>;
          })}
            </DisclosurePanel>
          </>}
      </Disclosure>
    </Auth>;
};
_s(MenuBox, "JcSve2Sl9BxNm9G+reCLMd1e1oQ=", false, function () {
  return [useLocation];
});
_c = MenuBox;
const processMobileMenu = ({
  navArray,
  administrator,
  showLabel,
  isExpanded
}) => navArray.map((item, idx) => {
  return item.children ? <li key={`${item.href}-${idx}`} className="w-full">
        <MenuBox key={item.href} administrator={administrator} item={item} isExpanded={showLabel} />
      </li> : <Auth customUser={administrator.user} permissions={item.auth} key={item.name}>
        <li className="w-full">
          <a href={item.href} className={clsx('group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold', {
        'bg-gray-800 text-white': item.current,
        'text-gray-400 hover:text-white hover:bg-gray-800 ': !item.current
      })}>
            <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />

            <span className={clsx(isExpanded ? 'inline' : 'hidden')}>
              {item.name}
            </span>
          </a>
        </li>
      </Auth>;
});
export const LeftMenu = ({
  administrator,
  activeMenu,
  isOpen,
  onToggleSidebar
}) => {
  _s2();
  const [isExpanded, setExpanded] = useState(true);
  // React.useEffect(() => {
  //   if (isExpanded != isOpen) {
  //     setExpanded(isOpen)
  //   }
  // }, [isOpen, isExpanded])
  return <>
      <Transition show={isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-30 lg:hidden" onClose={onToggleSidebar}>
          <TransitionChild as={React.Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-900/80" />
          </TransitionChild>

          <div className="fixed inset-0 flex">
            <TransitionChild as={React.Fragment} enter="transition ease-in-out duration-300 transform" enterFrom="-translate-x-full" enterTo="translate-x-0" leave="transition ease-in-out duration-300 transform" leaveFrom="translate-x-0" leaveTo="-translate-x-full">
              <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild as={React.Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => onToggleSidebar(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <div className="flex grow flex-col gap-y-5 overflow-y-auto no-scrollbar bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                  <div className="flex h-16 shrink-0 items-center">
                    <img className="h-8 w-auto" src="/icon-metrix.svg" alt="Metrix" />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="-mx-2 flex-1 space-y-1">
                      {processMobileMenu({
                      navArray: navigation,
                      administrator,
                      showLabel: true,
                      isExpanded
                    })}
                    </ul>
                    <div>
                      <ul role="list" className={clsx('-mx-2 flex flex-col items-start space-y-1 grow-0 shrink-0 transition-all duration-300 no-scrollbar ease-in-out')}>
                        {processMobileMenu({
                        navArray: settingsNavigation,
                        administrator,
                        showLabel: true,
                        isExpanded
                      })}
                      </ul>
                      <ul role="list" className={clsx('-mx-2 flex flex-col items-start space-y-1 grow-0 shrink-0 transition-all duration-300 no-scrollbar ease-in-out')}>
                        {processMobileMenu({
                        navArray: systemNavigation,
                        administrator,
                        showLabel: true,
                        isExpanded
                      })}
                      </ul>
                    </div>
                  </nav>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>

      {/* Static sidebar for desktop */}
      <div className="hidden overflow-y-auto flex grow flex-col lg:fixed lg:inset-y-0 lg:left-0 lg:bottom-0 lg:z-30 lg:block lg:w-20 lg:bg-gray-900 lg:pb-4 transition-all duration-300 no-scrollbar ease-in-out transform-gpu px-6 hover:w-72 lg:flex lg:flex-col" onMouseEnter={() => {
      setExpanded(true);
    }} onMouseLeave={() => {
      setExpanded(false);
    }}>
        <div className="flex h-16 shrink-0 items-center justify-start mt-4">
          <img className="h-14 w-auto" src="/icon-metrix.svg" alt="Logo" />
        </div>
        <nav className="mt-8 flex flex-col justify-between flex-1">
          <ul role="list" className={clsx('-mx-2 flex flex-col items-start space-y-1 flex-1 transition-all duration-300 no-scrollbar ease-in-out lg:overflow-y-auto')}>
            {processMobileMenu({
            navArray: navigation,
            administrator,
            showLabel: true,
            isExpanded
          })}
          </ul>
          <div>
            <ul role="list" className={clsx('-mx-2 flex flex-col items-start space-y-1 flex-1 transition-all duration-300 no-scrollbar ease-in-out lg:overflow-y-auto')}>
              {processMobileMenu({
              navArray: settingsNavigation,
              administrator,
              showLabel: true,
              isExpanded
            })}
            </ul>
            <Auth customUser={administrator.user} permissions={[Permission.ReadSystem]} key="system">
              <ul role="list" className={clsx('-mx-2 flex flex-col items-start space-y-1 flex-1 transition-all duration-300 no-scrollbar ease-in-out lg:overflow-y-auto')}>
                {processMobileMenu({
                navArray: systemNavigation,
                administrator,
                showLabel: true,
                isExpanded
              })}
              </ul>
            </Auth>
          </div>
        </nav>
      </div>
    </>;
};
_s2(LeftMenu, "rMt95KXb2l6k0ObzsZQDwTQHU0U=");
_c2 = LeftMenu;
var _c, _c2;
$RefreshReg$(_c, "MenuBox");
$RefreshReg$(_c2, "LeftMenu");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;